<template>
     <div  class="pa-5 white">
        <block v-if="loading" />
           
        <v-row class="m-3">
            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="search.showUnbalanced"
                   
                    label="Mostrar partidas descuadradas"
                    single-line
                    hide-details
                ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="search.showBalanced"
                    label="Mostrar partidas cuadradas"
                    single-line
                    hide-details
                ></v-checkbox>
            </v-col>
        </v-row>
        <date-filter class="item" v-if="!loading" @filtered-data="dateFilterHandler" :dataToFilter="data" nameOfDate="History" :filterEndpoint="$API.accountlevel.getAcountingHeaderFilteredBydate" :isFilteredByOutsource="isFiltered"/>

        <GeneralFilter
        entity="lista"
        :filterEndpoint="$API.accountlevel.getAcountingHeaderFiltered"
        :search="search"
        @emptyFilter="getAcounteringHeader"
        @filtered="filterHandler"
        :twoSlots="true">
            <DxDataGrid
                class="mt-5 isScrolledByGeneralFilter"
                :dataSource="itemsHistory"
                :showBorders="true"
                :showRowLines="true"
                v-if="$store.getters['rolesUser/getCustomRole'](205)"
                >
                <DxColumn cell-template="show-details"  :width="50" v-if="$store.getters['rolesUser/getCustomRole'](202)"></DxColumn>
                <!-- <DxColumn dataField="id_accounting_entry" caption="ID" :width="50"></DxColumn> -->
                <DxColumn cell-template="imbalance" :width="50"></DxColumn>
                <DxColumn dataField="ID_Accounting_item" caption="Correlativo"  ></DxColumn>
                <DxColumn dataField="EntryFormatDate" caption="Fecha"  ></DxColumn>
                <DxColumn dataField="accounting_entry_comment" caption="Concepto" ></DxColumn>
                <DxColumn dataField="accounting_entry_value_toShow" caption="Valor" ></DxColumn>
                <DxColumn dataField="typeAcountname" caption="Tipo" ></DxColumn>

                <DxPager :showPageSizeSelector="true" />
                <DxPaging :pageSize="5" />
                <template #show-details="{data}">
                    <div>
                        <a href="#" class="mx-1"
                            @click="entryTypes != undefined ? sendetails(data.data) : accountingEntrySelected(data)">
                            <v-icon color="primary">mdi-eye</v-icon>
                        </a>
                    </div>
                </template>
                <template #imbalance="{data}">
                <div>
                    <v-icon 
                    color="error"
                    v-if="data.data.accounting_entry_value < 0" title="Se indica la partida como descuadrada">mdi-alert</v-icon>
                    <v-icon 
                    color="success"
                    v-else title="La partida cuadra">mdi-check</v-icon>
                </div>
            </template>
            </DxDataGrid>
        </GeneralFilter>
    </div>
</template>
<script>
import DateFilter from "@/components/DateFilter";
import Block from "@/components/Block.vue";
import GeneralFilter from "@/components/GeneralFilter";
import {DxDataGrid, DxColumn,  DxPager, DxPaging,} from 'devextreme-vue/data-grid';
import { multipleCriteriaFilter } from "../../../helpers/object";
    export default {
        name: "AcountItemHistory",
        props: ['win', 'entryTypes'],
        components: {
            DxDataGrid,
            DxColumn,
            DxPager,
            DxPaging,
            GeneralFilter,
            DateFilter,
            Block
        },
        computed:{
            itemsHistory(){
                if(this.search.showUnbalanced && this.search.showBalanced){
                    return this.data;
                }
                if(this.search.showUnbalanced){
                    return this.data.filter((item) => item.accounting_entry_value < 0)
                }
                if(this.search.showBalanced){
                    return this.data.filter((item) => item.accounting_entry_value >= 0)
                }

                return [];
            },
  
        },
        data() {
            return {
                panel: 0,
                isFiltered: false,
                data: [],
                loading: true,
                search: {
                    filter: null,
                    showUnbalanced: true,
                    showBalanced: true,
                },
                NewTypesItems: null,
                Currency: JSON.parse(localStorage.getItem('branch')).Currency
            }
        },
        mounted() {
            this.loading = true;
            if(this.entryTypes == null || this.entryTypes == undefined){
                    this.getAcounteringType();
                    
            }
            else{
                this.getAcounteringHeader();
            }
            
        },
        methods: {
            accountingEntrySelected(data) {
                // console.log('data.data ', data.data);
                const { data: { id_accounting_entry: idAccountingEntry } } = data;
                let send = this.data.find( ( { id_accounting_entry } ) => id_accounting_entry == idAccountingEntry);
                // this.$store.commit('customerData/changeView', 'tab-1'); 
                this.$store.dispatch('addWindow', {
                    name: 'Acounting',
                    component: 'Acounting',
                    unique: false,
                    meta: { accountingEntryToModify: {...send} }
                })
            },
            dateFilterHandler(response) {
                if(response.data == null || response.data == undefined){
                    this.data = this.assignEntryTypes(response);
                    return;
                }
                this.data = this.assignEntryTypes(response.data);
            },
            getAcounteringType() {
                this.$API.branchaccountcatalog
                    .getAcounteringType()
                    .then((res) => {
                    this.NewTypesItems = res.data;
                    this.getAcounteringHeader();

                    })
                    .catch((err) => {
                    console.log(err);
                    });
            },
            sendetails(data){
                this.$emit('sendetails', data)
            },
            getHistory(){
                console.log('Obtener datos')
            },
            getAcounteringHeader(){
                this.isFiltered = false;
                this.loading = true;
                this.$API.accountlevel.getAcountingHeader().then((res) => {
                    this.data = this.assignEntryTypes(res.data);
                    this.loading = false
                })
                .catch((err) => {
                    console.log(err);
                });
            },
            filterHandler(response) {
                this.isFiltered = true;
                this.data = this.assignEntryTypes(response.data);
            },
            assignEntryTypes(data) {
                
                for(const index in data) {
                    const {
                        id_accounting_entry_type,
                    } = data[index];
                    data[index].accounting_entry_type = !this.NewTypesItems ? this.entryTypes.find( current => current.id_accounting_entry_type === id_accounting_entry_type ).accounting_entry_code
                    : this.NewTypesItems.find( current => current.id_accounting_entry_type === id_accounting_entry_type ).accounting_entry_code;
                }

                return data;
            },
            async auxFilter(filterObj) {
                const dataToFilter = await this.$API.accountlevel.getAcountingHeaderFiltered(filterObj);
                const filterArray = [
                    "accounting_entry_type"
                ];

                return new Promise( ( resolve, reject ) => {
                    try {
                        const filterResult = multipleCriteriaFilter( filterArray, filterObj, dataToFilter.length > 0 ? dataToFilter : this.data );

                        resolve( filterResult.length > 0 ? filterResult : dataToFilter );
                    } catch (error) {
                        reject( error );
                    }
                } );
                    
            }
        },
    }
</script>